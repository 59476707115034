import { number } from "joi";
import { mjeseci } from "./constants";

export const randomSixNumbers = () =>
    Math.floor(100000 + Math.random() * 900000);

export const fetcher = (url: string) => fetch(url).then((res) => res.json());

export const validateEmail = (email: string) =>
    email?.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

export const getExpiringInDays = (date: string) => {
    if (!date) {
        return {
            type: "greska",
            days: 0,
        };
    }
    const date1 = new Date(date).getTime();
    const date2 = new Date().getTime();
    const diffTime = Math.abs(date2 - date1);
    const dana = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (date1 - date2 < 0) {
        return {
            type: "istekao",
            days: dana,
        };
    }
    return {
        type: "aktivan",
        days: dana,
    };
};

export const formatDate = (date: string) => {
    const formatted = new Date(date);
    const godina = formatted.getFullYear();
    const mjesec = mjeseci[formatted.getMonth()];
    const dan =
        formatted.getUTCDate() < 9
            ? `0${formatted.getUTCDate()}`
            : formatted.getUTCDate();

    return `${dan}. ${mjesec} ${godina}`;
};

export const getTagLabel = (tag) =>
    tag ? tag.slice(0, -4).replace(/-/g, "") : "";

export const getLocationLabel = (locationName) => {
    return locationName.replace("-", " ");
};
/**
 * Vraca tekst skracen na pruzeni broj karaktera,
 * osiguravajuci da se rijec ne prekine na pola.
 *
 * @param text - Tekst koji se skracuje.
 * @param maxLength - broj karaktera na koji se skracuje.
 * @returns Skraceni tekst.
 */
export const trimText = (text: string, maxLength: number) => {
    const index = text.indexOf(" ", maxLength);
    return index === -1 ? text : text.substring(0, index);
};
