const revalidate = 600;

const jobsPerPage = 12;

const companiesPerPage = 21;

const limitForBuild = 20;

const getCompanySize = {
    0: "< 10",
    1: "10 - 99",
    2: "100 - 999",
    3: "1000+",
    4: "2000+",
};

const mjeseci = [
    "Januar",
    "Februar",
    "Mart",
    "April",
    "Maj",
    "Jun",
    "Jul",
    "Avgust",
    "Septembar",
    "Oktobar",
    "Novembar",
    "Decembar",
];

const newsletterTypes = [
    {
        value: "backend",
        label: "Backend Developer",
    },
    {
        value: "frontend",
        label: "Frontend Developer",
    },
    {
        value: "fullstack",
        label: "Full Stack Developer",
    },
    {
        value: "qa",
        label: "QA guru",
    },
    {
        value: "design",
        label: "Dizajner",
    },
    {
        value: "marketing",
        label: "Marketing",
    },
    {
        value: "management",
        label: "Menadzment",
    },
];

const levels = [
    {
        slug: "junior",
        name: "Junior",
    },
    {
        slug: "medior",
        name: "Medior",
    },
    {
        slug: "senior",
        name: "Senior",
    },
];

const types = [
    {
        slug: "full-time",
        name: "Puno radno vrijeme",
    },
    {
        slug: "part-time",
        name: "Pola radnog vremena",
    },
    // {
    //     slug: "po-projektu",
    //     name: "Po projektu",
    // },
    {
        slug: "internship",
        name: "Praksa",
    },
];

const department = [
    {
        slug: "frontend",
        name: "Front-End Developer",
    },
    {
        slug: "backend",
        name: "Back-End Developer",
    },
    {
        slug: "full-stack",
        name: "Full Stack Developer",
    },
    {
        slug: "design",
        name: "Design",
    },
    {
        slug: "qa",
        name: "Quality Assurance",
    },
    {
        slug: "marketing",
        name: "Marketing",
    },
    {
        slug: "management",
        name: "Management",
    },
];

const companyTypes = [
    {
        slug: "software",
        name: "Software",
    },
    {
        slug: "marketing",
        name: "Marketing",
    },
    {
        slug: "gaming",
        name: "Gaming",
    },
    {
        slug: "telekomunikacije",
        name: "Telekomunikacije",
    },
    {
        slug: "finansijski-sektor",
        name: "Finansijski sektor",
    },
    {
        slug: "design",
        name: "Design",
    },
    {
        slug: "medij",
        name: "Medij",
    },
    {
        slug: "ecommerce",
        name: "Ecommerce",
    },
    {
        slug: "sektor-za-edukaciju",
        name: "Sektor za edukaciju",
    },
    {
        slug: "hardware",
        name: "Hardware",
    },
    {
        slug: "gambling",
        name: "Gambling",
    },
    {
        slug: "medical",
        name: "Medical",
    },
    {
        slug: "ostalo",
        name: "Ostalo",
    },
];

const cities = [
    {
        slug: "remote",
        name: "Remote",
    },
    {
        slug: "sarajevo",
        name: "Sarajevo",
    },
    {
        slug: "banja-luka",
        name: "Banja Luka",
    },
    {
        slug: "tuzla",
        name: "Tuzla",
    },
    {
        slug: "zenica",
        name: "Zenica",
    },
    // {
    //     slug: "bijeljina",
    //     name: "Bijeljina",
    // },
    {
        slug: "mostar",
        name: "Mostar",
    },
    // {
    //     slug: "prijedor",
    //     name: "Prijedor",
    // },
    {
        slug: "doboj",
        name: "Doboj",
    },
    // {
    //     slug: "cazin",
    //     name: "Cazin",
    // },
    // {
    //     slug: "beograd",
    //     name: "Beograd",
    // },
    // {
    //     slug: "zagreb",
    //     name: "Zagreb",
    // },
    // {
    //     slug: "novi-sad",
    //     name: "Novi Sad",
    // },
    // {
    //     slug: "gradiska",
    //     name: "Gradiska",
    // },
];

const workPositions = [
    "Internship Software Engineer",
    "Junior Software Engineer",
    "Medior Software Engineer",
    "Senior Software Engineer",
    "Lead Software Engineer",
    "Software Architect",
    "Solution Architect",
    "Junior BI Developer",
    "Medior BI Developer",
    "Senior BI Developer",
    "Junior Business Analyst",
    "Medior Business Analyst",
    "Senior Business Analyst",
    "CTO",
    "Junior Delivery Manager",
    "Medior Delivery Manager",
    "Senior Delivery Manager",
    "Junior DevOps Engineer",
    "Medior DevOps Engineer",
    "Senior DevOps Engineer",
    "Junior Graphic Designer",
    "Medior Graphic Designer",
    "Senior Graphic Designer",
    "Junior Hardware Engineer",
    "Medior Hardware Engineer",
    "Senior Hardware Engineer",
    "IT Consultant",
    "IT Specialist",
    "Junior QA Specialist",
    "Medior QA Specialist",
    "Senior QA Specialist",
    "SEO Specialist",
    "Junior System Administrator",
    "Medior System Administrator",
    "Senior System Administrator",
    "Technical Lead",
    "Technical Writer",
    "Junior Test Engineer",
    "Medior Test Engineer",
    "Senior Test Engineer",
    "Junior Database Administrator",
    "Medior Database Administrator",
    "Senior Database Administrator",
    "2D/3D Artist",
    "Game Producer",
    "Game Designer",
    "Ostalo",
    "Yocto",
    "Junior iOS Developer",
    "Medior iOS Developer",
    "Senior iOS Developer",
    "Lead iOS Developer",
    "Lead Android Developer",
    "Junior Android Developer",
    "Medior Android Developer",
    "Senior Android Developer",
    "Junior Data Science Engineer",
    "Medior Data Science Engineer",
    "Senior Data Science Engineer",
    "Junior IT Manager",
    "Medior IT Manager",
    "Senior IT Manager",
    "Junior Product Manager",
    "Medior Product Manager",
    "Senior Product Manager",
    "Junior Project Manager",
    "Medior Project Manager",
    "Senior Project Manager",
    "Junior Scrum Master",
    "Medior Scrum Master",
    "Senior Scrum Master",
    "Junior SEO Specialist",
    "Medior SEO Specialist",
    "Senior SEO Specialist",
    "Internship System Administrator",
    "Junior Technical Writer",
    "Medior Technical Writer",
    "Senior Technical Writer",
    "Internship Technical Writer",
    "Junior UX/UI Designer",
    "Medior UX/UI Designer",
    "Senior UX/UI Designer",
    "Internship UX/UI Designer",
    "Junior Web Designer",
    "Medior Web Designer",
    "Senior Web Designer",
    "Internship Web Designer",
    "Internship Test Engineer",
    "Internship Database Administrator",
    "Junior 2D/3D Artist",
    "Medior 2D/3D Artist",
    "Senior 2D/3D Artist",
    "Junior Game Producer",
    "Medior Game Producer",
    "Senior Game Producer",
    "Junior Game Designer",
    "Medior Game Designer",
    "Senior Game Designer",
    "Internship Game Designer",
    "Junior Customer Support",
    "Medior Customer Support",
    "Senior Customer Support",
    "Internship Customer Support",
    "Junior Sales Department",
    "Medior Sales Department",
    "Senior Sales Department",
    "Internship Sales Department",
    "Junior Marketing Department",
    "Medior Marketing Department",
    "Senior Marketing Department",
    "Internship Marketing Department",
    "Junior HR Department",
    "Medior HR Department",
    "Senior HR Department",
    "Internship HR Department",
    "Junior Finance Department",
    "Medior Finance Department",
    "Senior Finance Department",
    "Junior Administration",
    "Medior Administration",
    "Senior Administration",
    "Junior Product Owner",
    "Medior Product Owner",
    "Senior Product Owner",
    "Junior Animator",
    "Medior Animator",
    "Senior Animator",
];

const tags = [
    { name: ".NET" },
    { name: "2D" },
    { name: "3D" },
    { name: "3G" },
    { name: "ABAP" },
    { name: "ActiveMQ" },
    { name: "ADO" },
    { name: "Adobe Flex" },
    { name: "Adobe Illustrator CS6" },
    { name: "Adobe InDesign CS6" },
    { name: "Adobe Photoshop" },
    { name: "Agile" },
    { name: "Ajax" },
    { name: "Alfresco" },
    { name: "AlloyUI" },
    { name: "Altium Designer" },
    { name: "Android" },
    { name: "Angular" },
    { name: "Ansible" },
    { name: "Ant" },
    { name: "Anthill" },
    { name: "Apache" },
    { name: "Apache CXF" },
    { name: "Apache Thrift" },
    { name: "APC" },
    { name: "APEX" },
    { name: "Appium" },
    { name: "ArchiveLink" },
    { name: "ARM" },
    { name: "Asic" },
    { name: "ASW" },
    { name: "Atlassian Bamboo" },
    { name: "Aurora" },
    { name: "AutoCAD" },
    { name: "AWS" },
    { name: "Azure" },
    { name: "Backbone" },
    { name: "Balsamiq" },
    { name: "Bash" },
    { name: "Batch" },
    { name: "BEX Analyzer" },
    { name: "BGP" },
    { name: "BizTalk" },
    { name: "BlackBerry" },
    { name: "Bootstrap" },
    { name: "Brocade" },
    { name: "C" },
    { name: "C#" },
    { name: "C++" },
    { name: "CAD" },
    { name: "Camel" },
    { name: "Canvas" },
    { name: "Cassandra" },
    { name: "CATIA" },
    { name: "Check Point" },
    { name: "Chef" },
    { name: "CISCO" },
    { name: "Citrix" },
    { name: "Cloud" },
    { name: "CMS" },
    { name: "Cocoa" },
    { name: "Cocoa Touch" },
    { name: "CodeIgniter" },
    { name: "Confluence" },
    { name: "Cordova" },
    { name: "Couchbase" },
    { name: "CRM" },
    { name: "CSS" },
    { name: "CSS3" },
    { name: "Cucumber" },
    { name: "CVS" },
    { name: "Cypress" },
    { name: "Dart" },
    { name: "Delphi" },
    { name: "DevOps" },
    { name: "Django" },
    { name: "DNS" },
    { name: "Docker" },
    { name: "Doxygen" },
    { name: "Drupal" },
    { name: "EIGRP" },
    { name: "ElasticSearch" },
    { name: "Embedded" },
    { name: "EmberJS" },
    { name: "Entity Framework" },
    { name: "Erp" },
    { name: "Exchange server" },
    { name: "ExtJS" },
    { name: "Figma" },
    { name: "Firebase" },
    { name: "Fireworks" },
    { name: "Foundation" },
    { name: "FPGA" },
    { name: "FreeBSD" },
    { name: "Git" },
    { name: "Glassfish" },
    { name: "Golang" },
    { name: "Groovy" },
    { name: "Grunt/Bower" },
    { name: "GSM" },
    { name: "hadoop" },
    { name: "HandlebarsJS" },
    { name: "Hardware" },
    { name: "HBase" },
    { name: "HDFS" },
    { name: "Hibernate" },
    { name: "HSRP" },
    { name: "HTML" },
    { name: "HTML5" },
    { name: "HTTP" },
    { name: "Hudson" },
    { name: "HVAC" },
    { name: "IBatis" },
    { name: "IIS" },
    { name: "Impala" },
    { name: "iOS" },
    { name: "IP Telephony" },
    { name: "IPS" },
    { name: "IPSEC" },
    { name: "ISUP" },
    { name: "j2ee" },
    { name: "Jest" },
    { name: "J2ME" },
    { name: "Jasper" },
    { name: "Java" },
    { name: "Java EE" },
    { name: "JavaScript" },
    { name: "Jboss" },
    { name: "JDBC" },
    { name: "JDeveloper" },
    { name: "JEE" },
    { name: "Jenkins" },
    { name: "Jira" },
    { name: "JMeter" },
    { name: "JMS" },
    { name: "JMX" },
    { name: "Joomla" },
    { name: "JPA" },
    { name: "jQuery" },
    { name: "JSF" },
    { name: "JSP-UI" },
    { name: "Juniper" },
    { name: "JUnit" },
    { name: "Kafka" },
    { name: "KnockoutJS" },
    { name: "Kotlin" },
    { name: "Kubernetes" },
    { name: "LabView" },
    { name: "LAMP" },
    { name: "Laravel" },
    { name: "LibACE" },
    { name: "LINQ" },
    { name: "Linux" },
    { name: "Log4cplus" },
    { name: "Log4J" },
    { name: "LTE" },
    { name: "LTspicelV" },
    { name: "Lua" },
    { name: "M2M" },
    { name: "Magento" },
    { name: "Mahout" },
    { name: "MAP" },
    { name: "MariaDB" },
    { name: "Marionette" },
    { name: "Marshalling" },
    { name: "Matlab" },
    { name: "Maven" },
    { name: "Maya" },
    { name: "Memcached" },
    { name: "Mercurial" },
    { name: "Microservices" },
    { name: "Microsoft Dynamics AX" },
    { name: "Microsoft Dynamics NAV" },
    { name: "Microsoft SQL Server" },
    { name: "Microsoft TFS" },
    { name: "Microsoft TFS Build" },
    { name: "MIPS" },
    { name: "MongoDB" },
    { name: "Mono" },
    { name: "Mono for Android" },
    { name: "MonoTouch" },
    { name: "MS Access" },
    { name: "MS SQL" },
    { name: "MS Visual Studio" },
    { name: "MS Windows Server" },
    { name: "MS-HN" },
    { name: "MustacheJS" },
    { name: "MVC" },
    { name: "MVVM" },
    { name: "MySQL" },
    { name: "Network Administrator" },
    { name: "Nexus" },
    { name: "NginX" },
    { name: "NHibernate" },
    { name: "Node.js" },
    { name: "NoSQL" },
    { name: "Objective-C" },
    { name: "ODBC" },
    { name: "OLE" },
    { name: "OOP" },
    { name: "OpenCart" },
    { name: "OpenX" },
    { name: "Oracle" },
    { name: "Osgi" },
    { name: "OSPF" },
    { name: "PaloAlto" },
    { name: "Perforce" },
    { name: "Perl" },
    { name: "PHP" },
    { name: "Unit Testing" },
    { name: "Play Framework" },
    { name: "Post gres" },
    { name: "PostgreSQL" },
    { name: "PostScript" },
    { name: "PowerShell" },
    { name: "Prototype" },
    { name: "Proxy" },
    { name: "PSQL" },
    { name: "Puppet" },
    { name: "Python" },
    { name: "QA" },
    { name: "QlickView" },
    { name: "QoS" },
    { name: "R" },
    { name: "RabbitMQ" },
    { name: "RDBMS" },
    { name: "React" },
    { name: "React Native" },
    { name: "Redis" },
    { name: "RedShift" },
    { name: "RegEx" },
    { name: "Release Engineer" },
    { name: "RequireJS" },
    { name: "RESTful" },
    { name: "React Testing Library" },
    { name: "Ruby" },
    { name: "Ruby on Rails" },
    { name: "SaaS" },
    { name: "SAP" },
    { name: "Savant" },
    { name: "Scala" },
    { name: "SCRUM" },
    { name: "Selenium" },
    { name: "SharePoint" },
    { name: "Shell Scripting" },
    { name: "Sigtran" },
    { name: "Silverlight" },
    { name: "SIP" },
    { name: "Sitecore" },
    { name: "Sketch" },
    { name: "Smarty" },
    { name: "SNMP" },
    { name: "SOA" },
    { name: "SOAP" },
    { name: "SoC" },
    { name: "Software Tester" },
    { name: "SONAR" },
    { name: "Spark" },
    { name: "Specman" },
    { name: "Spring" },
    { name: "SQL" },
    { name: "SQL Server" },
    { name: "SQLite" },
    { name: "SS7" },
    { name: "STCP" },
    { name: "STL" },
    { name: "STP" },
    { name: "Struts" },
    { name: "Subversion" },
    { name: "Svn" },
    { name: "Swift" },
    { name: "Swing" },
    { name: "Symfony" },
    { name: "TCP/IP" },
    { name: "Telematics" },
    { name: "TestNG" },
    { name: "TestRail" },
    { name: "Tomcat" },
    { name: "TravisCI" },
    { name: "TSQL" },
    { name: "Twig" },
    { name: "TypeScript" },
    { name: "TYPO3" },
    { name: "Umbraco" },
    { name: "UML" },
    { name: "UMTS" },
    { name: "UnitTest" },
    { name: "Unity" },
    { name: "Unix" },
    { name: "Vagrant" },
    { name: "Varnish" },
    { name: "VB.NET" },
    { name: "VDHL" },
    { name: "VHDL" },
    { name: "Visual basic" },
    { name: "Visual Studio" },
    { name: "VMware" },
    { name: "VOIP" },
    { name: "VPN" },
    { name: "Vue.js" },
    { name: "WCF" },
    { name: "WEB API" },
    { name: "WebForms" },
    { name: "WebGL" },
    { name: "WebServices" },
    { name: "WebSockets" },
    { name: "Websphere" },
    { name: "Win32 API" },
    { name: "WinDev" },
    { name: "Windows" },
    { name: "Windows Phone" },
    { name: "Wordpress" },
    { name: "WPF" },
    { name: "Xamarin" },
    { name: "Xen" },
    { name: "XHTML" },
    { name: "XPATH" },
    { name: "XSD" },
    { name: "XSLT" },
    { name: "Zend" },
    { name: "Zephyr" },
    { name: "Blockchain" },
    { name: "NFT" },
    { name: "Lambda" },
    { name: "S3" },
    { name: "VPC" },
    { name: "EC2" },
    { name: "EFS" },
    { name: "CodePipeline" },
    { name: "Facebook" },
    { name: "Linkedin" },
    { name: "Instagram" },
    { name: "CI/CD" },
    { name: "Facebook" },
    { name: "Linkedin" },
    { name: "Instagram" },
    { name: "CI/CD" },
    { name: "Next.js" },
    { name: "WebRTC" },
    { name: "SEO" },
    { name: "Redux" },
    { name: "Swagger" },
    { name: "Expo" },
    { name: "Zeplin" },
    { name: "Webpack" },
    { name: "Babel" },
    { name: "HR" },
    { name: "Electron" },
    { name: "WebKit" },
    { name: "PWA" },
    { name: "web3" },
    { name: "chart.js" },
    { name: "Solidity" },
    { name: "Crypto" },
    { name: "GraphQL" },
    { name: "VPE" },
    { name: "WooCommerce" },
    { name: "SASS" },
    { name: "MobX" },
    { name: "Terraform" },
    { name: "Apollo" },
    { name: "Enzyme" },
    { name: "NestJS" },
    { name: "Koa" },
    { name: "Express" },
    { name: "Google Ads" },
    { name: "Google Analytics" },
    { name: "Youtube" },
    { name: "FinTech" },
    { name: "Sales" },
    { name: "Support" },
    { name: "CCNA" },
    { name: "CCNP" },
    { name: "UX" },
    { name: "recruiter" },
    { name: ".Net core" },
    { name: "Salesforce" },
    { name: "Flutter" },
];

const newTags = [];

module.exports = {
    getCompanySize,
    levels,
    types,
    department,
    cities,
    tags,
    newTags,
    revalidate,
    companyTypes,
    jobsPerPage,
    newsletterTypes,
    limitForBuild,
    mjeseci,
    workPositions,
    companiesPerPage,
};
