import { useEffect, useRef } from "react";

type AlertType = {
    message: string;
    type: "success" | "error";
    action?: Function;
};

export default function Alert({ message, type, action }: AlertType) {
    const timeout = useRef(null);

    useEffect(() => {
        if (action) {
            timeout.current = setTimeout(() => {
                action(false);
            }, 5000);
        }
        return () => {
            if (action) {
                clearTimeout(timeout.current);
            }
        };
    }, []);
    return (
        <div
            className={`z-50 fixed h-10 w-full top-0 left-0 text-center ${
                type === "success" ? "bg-green-500" : "bg-red"
            } text-white leading-10`}
        >
            {message}
        </div>
    );
}
